/**
 *
 *
 * useDeletePost
 *
 * Handles state for opening a confirmation modal and deleting a post.
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Quote } from "../components/Quote";
import { PrimaryPostBody } from "../containers/PostV3/PrimaryPostBody";
import { queryClient } from "../query-client";
import {
  useSocialPostsCommentsDestroy,
  useSocialPostsCommentsRepliesDestroy,
  useSocialPostsCommentsRepliesRebuttalsDestroy,
  useSocialPostsDestroy,
} from "../services/teambuilder/endpoints/social/social";
import {
  PaginatedCommentList,
  PaginatedPostList,
  PaginatedReplyList,
  Post,
} from "../services/teambuilder/schemas";
import { PostType } from "../types";
import { capitalize } from "../utils/capitalize";
import { optimisticMutationOptions } from "../utils/optimistic-update";
import { parseRoles } from "../utils/parse-roles";
import { getKindOfPost, getPostAncestorPks } from "../utils/post-utils";
import {
  isCommentType,
  isPostType,
  isRebuttalType,
  isReplyType,
} from "../utils/type-guards";
import { fullName } from "../utils/username";
import { useConfirmationModal } from "./useConfirmationModal";
import { useMe } from "./useMe";
import { usePostFeedListParams } from "./usePostFeedList";
import { useToast } from "./useToast";

interface Props {
  queryKey: readonly unknown[];
  // required to refetch post when delete comment on post detail modal
  detailQueryKey?: readonly unknown[];
  onSettled?: () => void;
}

export const useDeletePost = (props: Props) => {
  const { openToast } = useToast();
  const { me } = useMe();
  const roles = useMemo(() => parseRoles(me?.roles || []), [me]);
  const { openConfirmationModal, closeConfirmationModal } =
    useConfirmationModal();
  const { t } = useTranslation();
  const feedParams = usePostFeedListParams();

  /**
   * Delete requests
   */
  const { mutate: deletePost } = useSocialPostsDestroy(
    optimisticMutationOptions<void, PaginatedPostList, { id: number }>({
      queryKey: props.queryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        meta: context.meta,
        data: context.data.filter((post) => post.id !== requestVariables?.id),
      }),
      onSettled: () => {
        openToast({
          title: t("translation:toast:post_delete_success"),
        });
        props.onSettled?.();
      },
    })
  );

  const { mutate: deleteComment } = useSocialPostsCommentsDestroy(
    optimisticMutationOptions<void, PaginatedCommentList, { id: number }>({
      queryKey: props.queryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        meta: context.meta,
        data: context.data.filter((post) => post.id !== requestVariables?.id),
      }),
      onSettled: () => {
        openToast({
          title: t("translation:toast:comment_delete_success"),
        });
        queryClient.invalidateQueries(props.queryKey);
        if (props.detailQueryKey) {
          queryClient.invalidateQueries(props.detailQueryKey);
        }
        feedParams.refetch();
      },
    })
  );

  const { mutate: deleteReply } = useSocialPostsCommentsRepliesDestroy(
    optimisticMutationOptions<void, PaginatedReplyList, { id: number }>({
      queryKey: props.queryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        meta: context.meta,
        data: context.data.filter((post) => post.id !== requestVariables?.id),
      }),
      onSettled: () => {
        openToast({
          title: t("translation:toast:comment_delete_success"),
        });
        queryClient.invalidateQueries(props.queryKey);
      },
    })
  );

  const { mutate: deleteRebuttal } =
    useSocialPostsCommentsRepliesRebuttalsDestroy(
      optimisticMutationOptions<void, PaginatedReplyList, { id: number }>({
        queryKey: props.queryKey,
        optimisticUpdateFn: (context, requestVariables) => ({
          meta: context.meta,
          data: context.data.filter((post) => post.id !== requestVariables?.id),
        }),
        onSettled: () => {
          openToast({
            title: t("translation:toast:comment_delete_success"),
          });
          queryClient.invalidateQueries(props.queryKey);
        },
      })
    );

  const onConfirmDelete = (post: PostType) => {
    const { postsPk, commentsPk, repliesPk } = getPostAncestorPks(post);
    closeConfirmationModal();
    if (isPostType(post)) {
      deletePost({ id: post.id as number });
    } else if (isCommentType(post)) {
      deleteComment({ id: post.id as number, postsPk });
    } else if (isReplyType(post)) {
      deleteReply({ id: post.id as number, postsPk, commentsPk });
    } else if (isRebuttalType(post)) {
      deleteRebuttal({
        id: post.id as number,
        postsPk,
        commentsPk,
        repliesPk,
      });
    }
  };

  const openDeleteConfirmationModal = (post: Post) => {
    const isMyPost = post.user?.id === me?.id;
    const author = fullName(post.user);
    const kindOfPost = getKindOfPost(post, t);
    openConfirmationModal({
      title: isMyPost
        ? t("translation:post:title_delete_your_post", { kindOfPost })
        : roles.isHR
          ? t("translation:post:title_hr_post", {
              author,
              kindOfPost,
            })
          : t("translation:post:title_post", { kindOfPost }),
      description: (
        <>
          {isMyPost
            ? t("translation:post:question_delete_your_post", { kindOfPost })
            : roles.isHR
              ? t("translation:post:question_delete_hr_post", {
                  author,
                  kindOfPost,
                })
              : t("translation:post:question_delete_post", { kindOfPost })}
          <Quote>
            <PrimaryPostBody post={post} />
          </Quote>
        </>
      ),
      confirmButtonTitle: t("translation:post:delete_button_post", {
        kindOfPost: capitalize(kindOfPost),
      }),
      variant: "warn",
      onConfirm: () => {
        onConfirmDelete(post);
      },
      isLoading: false,
    });
  };

  return {
    openDeleteConfirmationModal,
  };
};
