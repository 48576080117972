/**
 *
 *
 * is-production
 *
 *
 */

/**
 * Returns true if this is the "production" or "test" environment.
 */
const isProduction = (notTest = false): boolean =>
  import.meta.env.MODE === "prod" ||
  (!notTest && import.meta.env.MODE === "test");

export default isProduction;
