/**
 *
 *
 * buttonStyles
 *
 *
 */
import clsx from "clsx";

export type Variant =
  | "contained"
  | "highlight"
  | "challenge"
  | "outlined"
  | "ghost"
  | "inline"
  | "text"
  | "text-danger"
  | "success"
  | "danger"
  | "warn"
  | "skeleton"
  | "link"
  | "shoutout"
  | "image";

export type Size = "xl" | "lg" | "md" | "sm" | "xs";

interface Options {
  variant?: Variant;
  size?: Size;
}

export const buttonStyles = ({
  variant = "contained",
  size = "lg",
}: Options) => {
  const paddingStyles = {
    xs: "py-1 px-2",
    sm: "py-1 px-2",
    md: "py-2 px-3",
    lg: "py-2 px-3",
    xl: "px-2.5 py-3.5",
  };

  const textStyles: Record<Size, string> = {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-sm",
    lg: "text-md",
    xl: "text-lg",
  };

  const roundedStyles: Record<Size, string> = {
    xs: "rounded-md",
    sm: "rounded-md",
    md: "rounded-md",
    lg: "rounded-md",
    xl: "rounded-md",
  };

  const sharedStyles = clsx(
    "flex items-center justify-center gap-2",
    "font-medium",
    "tracking-wide",
    "disabled:cursor-not-allowed",
    "focus:outline-none",
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-400",
    paddingStyles[size],
    textStyles[size],
    roundedStyles[size]
  );

  const variantStyles: Record<Variant, string> = {
    contained: clsx(
      sharedStyles,
      "text-white",
      "shadow-sm disabled:shadow-none",
      "bg-zinc-600 hover:bg-zinc-700 disabled:opacity-70"
    ),
    highlight: clsx(
      sharedStyles,
      "text-white",
      "shadow-sm disabled:shadow-none",
      "bg-amber-700 hover:bg-amber-800 disabled:bg-amber-700/40"
    ),
    challenge: clsx(
      sharedStyles,
      "text-white",
      "shadow-sm disabled:shadow-none",
      "bg-challenge hover:bg-challenge-hover disabled:bg-challenge/70"
    ),
    outlined: clsx(
      sharedStyles,
      "bg-white",
      "shadow-sm disabled:shadow-none",
      "border border-zinc-300 text-zinc-900 hover:bg-zinc-50 disabled:text-zinc-900/40"
    ),
    ghost: clsx(
      sharedStyles,
      "bg-transparent hover:bg-zinc-50",
      "text-zinc-900 disabled:text-zinc-900/40"
    ),
    inline: clsx(
      textStyles[size],
      "text-zinc-500 hover:text-zinc-600",
      "disabled:text-zinc-500/40"
    ),
    text: clsx(
      sharedStyles,
      "bg-transparent",
      "text-zinc-500 hover:text-zinc-600"
    ),
    "text-danger": clsx(
      sharedStyles,
      "bg-transparent",
      "text-red-500 hover:text-red-600",
      "disabled:text-red-500/40"
    ),
    success: clsx(
      sharedStyles,
      "shadow-sm disabled:shadow-none",
      "bg-green-500 text-white hover:bg-green-600 focus:ring-green-500 disabled:bg-green-500"
    ),
    danger: clsx(
      sharedStyles,
      "shadow-sm disabled:shadow-none",
      "bg-red-700 text-white hover:bg-red-800 disabled:bg-red-500"
    ),
    warn: clsx(
      sharedStyles,
      "shadow-sm disabled:shadow-none",
      "bg-yellow-600 text-white hover:bg-yellow-700 focus:ring-yellow-600 disabled:opacity-80"
    ),
    shoutout: clsx(
      sharedStyles,
      "shadow-sm disabled:shadow-none",
      "bg-fuchsia-950 text-white hover:bg-fuchsia-900 focus:ring-fuchsia-900 disabled:bg-fuchsia-950/80"
    ),
    image: clsx(
      sharedStyles,
      "shadow-sm disabled:shadow-none",
      "bg-green-950 text-white hover:bg-green-900 focus:ring-green-900 disabled:bg-green-950/80"
    ),
    link: clsx(
      sharedStyles,
      "shadow-sm disabled:shadow-none",
      "bg-blue-950 text-white hover:bg-blue-900 focus:ring-blue-900 disabled:bg-blue-950/80"
    ),
    skeleton: clsx(sharedStyles, "bg-zinc-300", "text-white", "animate-pulse"),
  };

  return variantStyles[variant];
};
