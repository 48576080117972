/**
 *
 *
 * useQAndAForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { TEAM_EVERYONE_ID } from "../../consts";
import { useRoles } from "../../hooks/useRoles";
import { useToast } from "../../hooks/useToast";
import {
  getUserguidesAnswersListQueryKey,
  useUserguidesAnswersBulkEditCreate,
  useUserguidesAnswersList,
  useUserguidesQuestionsList,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  AnswerBulkEditRequest,
  QuestionModel,
  UserguidesQuestionsListParams,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

interface Props {
  userId: number;
  onCancel: () => void;
}

export const useQAndAForm = ({ userId, onCancel }: Props) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { isAdmin } = useRoles();

  /**
   * Form config
   */
  const schema = z.object({
    qas: z
      .array(
        z.object({
          id: z.number().optional(),
          questionId: z.number(),
          question: z.string(),
          answer: z.string().optional(),
        })
      )
      .optional(),
  });

  type FormData = z.infer<typeof schema>;

  const defaultValues: FormData = {
    qas: [],
  };

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "qas",
  });

  /**
   * Answers Bulk Edit
   */
  const answersListQueryKey = useMemo(
    () => getUserguidesAnswersListQueryKey({ user: userId }),
    [userId]
  );
  const { mutate: updateAnswers } = useUserguidesAnswersBulkEditCreate(
    optimisticMutationOptions<
      void,
      AnswerBulkEditRequest[],
      { data: AnswerBulkEditRequest[] }
    >({
      queryKey: answersListQueryKey,
      optimisticUpdateFn: (_context, requestVariables) =>
        requestVariables?.data || [],
      onSuccess: () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:answer_save_success"),
        });
        onCancel();
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:answer_save_failed"),
          type: "danger",
        });
      },
    })
  );

  const onSubmit = (formData: FormData) => {
    if (!formData.qas) return;
    updateAnswers({
      data: formData.qas
        .filter((qa) => !(qa.id === undefined && qa.answer === ""))
        .map((qa) => ({
          id: qa.id!,
          question: qa.questionId,
          answer: qa.answer || "",
        })),
    });
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  /**
   *
   * Questions and Answers
   *
   */
  const questionsListParams: UserguidesQuestionsListParams = {
    team: TEAM_EVERYONE_ID,
    ordering: "-created_at",
    limit: 999,
  };
  const { data: questions, isLoading: isLoadingQuestions } =
    useUserguidesQuestionsList(questionsListParams, {
      query: {
        select: ({ data }) => data,
      },
    });

  const { data: answers, isLoading: isLoadingAnswers } =
    useUserguidesAnswersList(
      { user: userId },
      {
        query: {
          select: ({ data }) => data,
        },
      }
    );

  /**
   * All questions including answers by the current user.
   */
  const questionsAndAnswers: QuestionModel[] = useMemo<QuestionModel[]>(() => {
    if (!questions) return [];

    return questions?.map((question) => {
      return {
        ...question,
        answers: answers?.filter((answer) => answer.question === question.id),
      };
    });
  }, [questions, answers]);

  useEffect(() => {
    if (questionsAndAnswers)
      form.reset({
        qas:
          questionsAndAnswers.map((qa) => ({
            id: qa.answers?.[0]?.id,
            question: qa.question,
            questionId: qa.id || Math.random(),
            answer: qa.answers?.[0]?.answer,
          })) || [],
      });
  }, [questionsAndAnswers]);

  return {
    isAdmin,
    isLoading: isLoadingQuestions || isLoadingAnswers,
    fields,
    form,
    onCancel,
    questionsAndAnswers,
    onClose: close,
    onHandleSubmit,
  };
};
