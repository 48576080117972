/**
 *
 *
 * useChallengeWizardPreview
 *
 *
 */
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import { useToast } from "../../hooks/useToast";
import {
  useChallengesPartialUpdate,
  useChallengesRetrieve,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import { PatchedChallengeRequest } from "../../services/teambuilder/schemas";

export const useChallengeWizardPreview = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const challengeId = Number(searchParams.get(SearchParam.CHALLENGE));

  const { openToast } = useToast();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [dateError, setDateError] = useState<string>();

  /**
   * Challenge requests
   */
  const { data: challenge, isInitialLoading: isLoadingChallenge } =
    useChallengesRetrieve(
      challengeId,
      {},
      {
        query: {
          enabled: challengeId > 0,
          select: (data) => data,
        },
      }
    );

  /**
   * update challenge
   */
  const { mutate: updateChallenge, isLoading: isLoadingUpdateChallenge } =
    useChallengesPartialUpdate({
      mutation: {
        onSuccess: (_data, variables) => {
          // Forward use to the challenge detail page
          openToast({ title: t("translation:toast:challenge_update_success") });
          navigate(`/challenges/${variables.id}`);
        },
        onError: () => {
          openToast({
            title: t("translation:toast:challenge_update_failed"),
            type: "danger",
          });
        },
      },
    });

  /**
   * onCancel : click on Cancel Button
   */
  const onCancel = () => {
    navigate("/challenges");
  };

  /**
   * go back
   */
  const onBack = () => {
    navigate(`/challenges/wizard/select-dates?${searchParams.toString()}`);
  };

  /**
   * Publish
   */
  const onNext = async () => {
    if (!challenge) return;
    const { startAt: szStartAt, endAt: szEndAt } = challenge;
    const now = dayjs().subtract(9, "minute");
    const startAt = dayjs(szStartAt);
    const endAt = dayjs(szEndAt);
    if (startAt.isBefore(now)) {
      setDateError(t("translation:validation:start_date_be_future_reset"));
      setIsOpenModal(false);
      return;
    }
    if (endAt.isBefore(now)) {
      setDateError(t("translation:validation:end_date_be_future_reset"));
      setIsOpenModal(false);
      return;
    }

    const data: { id: number; data: PatchedChallengeRequest } = {
      id: challengeId,
      data: { isDraft: false },
    };
    updateChallenge(data);
  };

  return {
    onCancel,
    onBack,
    onNext,

    isLoading: isLoadingChallenge || isLoadingUpdateChallenge,
    challenge,

    isOpenModal,
    setIsOpenModal,

    dateError,
  };
};
