/**
 *
 *
 * useChallengeConfirmationWithProofModal
 *
 *
 */
import { TrashIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import { usePostModalContent } from "../../hooks/usePostModalContent";
import {
  Activity,
  ActivityProofType,
  PostWriteRequest,
} from "../../services/teambuilder/schemas";
import { NavItemV3 } from "../../types";

export interface Props {
  isOpen: boolean;
  activity: Partial<Activity>;
  title: string;
  description?: React.ReactNode;
  children: React.ReactNode;
  cancelButtonTitle: string;
  confirmButtonTitle: string;
  onCancel: () => void;
  onConfirm: (data?: PostWriteRequest) => void;
}

export const useChallengeConfirmationWithProofModal = ({
  isOpen,
  activity,
  title,
  description,
  children,
  cancelButtonTitle,
  confirmButtonTitle,
  onCancel,
  onConfirm,
}: Props) => {
  const { t } = useTranslation();
  const [isFilled, setIsFilled] = useState(false);
  const {
    content,
    previewImage,
    previewImageUrl,
    selectedRecipient,
    selectedValueId,
    selectedURL,
    selectedValueLabel,
    reset,
  } = usePostModalContent();

  const onSubmit = () => {
    if (
      activity.proofType === ActivityProofType.not_required ||
      activity.proofType === ActivityProofType.shoutout ||
      activity.proofType === ActivityProofType.image ||
      activity.linkUrl
    ) {
      onConfirm();
    } else {
      if (isFilled) {
        const data: PostWriteRequest = {
          content,
          image: previewImage
            ? new File([previewImage], "image.png")
            : undefined,
          url: selectedURL,
        };
        onConfirm(data);
      }
    }
  };

  useEffect(() => {
    if (
      previewImage &&
      previewImageUrl &&
      activity.proofType == ActivityProofType.image
    ) {
      setIsFilled(true);
      return;
    }
    // if (
    //   content &&
    //   selectedRecipient &&
    //   selectedValueId &&
    //   selectedValueLabel &&
    //   activity.proofType == ActivityProofType.shoutout
    // ) {
    //   setIsFilled(true);
    //   return;
    // }
    if (
      content &&
      selectedURL &&
      (activity.proofType == ActivityProofType.link || activity.linkUrl)
    ) {
      setIsFilled(true);
      return;
    }
    if (content && activity.proofType === ActivityProofType.text) {
      setIsFilled(true);
      return;
    }
    setIsFilled(false);
  }, [
    content,
    previewImage,
    previewImageUrl,
    selectedRecipient,
    selectedValueId,
    selectedURL,
    selectedValueLabel,
    activity,
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const openPostModal = () => {
    reset();
    if (searchParams.has(SearchParam.SHOW_POST_MODAL)) {
      searchParams.set(SearchParam.SHOW_POST_MODAL, "1");
    } else {
      searchParams.append(SearchParam.SHOW_POST_MODAL, "1");
    }
    const feature =
      activity.proofType === ActivityProofType.image
        ? SearchParam.WITH_IMAGE
        : activity.proofType === ActivityProofType.shoutout
          ? SearchParam.WITH_SHOUTOUT
          : activity.proofType === ActivityProofType.link || activity.linkUrl
            ? SearchParam.WITH_URL
            : null;
    if (feature) {
      if (searchParams.has(feature)) {
        searchParams.set(feature, "1");
      } else {
        searchParams.append(feature, "1");
      }
    }
    searchParams.append(SearchParam.IS_CHALLENGE_COMPLETE, "true");
    setSearchParams(searchParams);
  };

  const menuItems: NavItemV3[] = [
    {
      name: t("translation:common:remove"),
      icon: TrashIcon,
      onClick: reset,
    },
  ];

  return {
    onConfirm,
    onSubmit,
    isOpen,
    title,
    description,
    children,
    cancelButtonTitle,
    confirmButtonTitle,
    activity,
    onCancel,
    isFilled,
    setIsFilled,
    openPostModal,
    content,
    previewImage,
    previewImageUrl,
    selectedRecipient,
    selectedValueId,
    selectedURL,
    selectedValueLabel,
    menuItems,
  };
};
