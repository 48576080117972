/**
 *
 *
 * <EmoticonModal />
 *
 *
 */
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useEmoticonModal } from "./useEmoticonModal";

const EmoticonModal = React.forwardRef<
  HTMLElement,
  ReturnType<typeof useEmoticonModal>
>((props, initialFocusRef) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  useOnClickOutside(ref, props.onClose);
  return (
    <Transition.Root appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        ref={ref}
        className={clsx("relative z-50 bg-opacity-25")}
        initialFocus={initialFocusRef as React.MutableRefObject<HTMLElement>}
        onClose={props.onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={clsx("fixed inset-0", "bg-zinc-500 bg-opacity-0")} />
        </Transition.Child>

        <div
          className="fixed inset-0 overflow-y-auto"
          data-testid="modal-transition-wrapper"
        >
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx("absolute sm:rounded-lg")}
                style={{
                  top: props.top,
                  right: props.right,
                }}
              >
                <div className="overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-zinc-300">
                  <ul className={clsx("flex border-b")}>
                    {props.categories.map((category) => (
                      <li key={`category-${category.key}`}>
                        <button
                          type="button"
                          onClick={() => {
                            if (props.searchRef.current) {
                              props.searchRef.current.value = "";
                            }
                            props.setFilteredEmojis(null);
                            props.onSearchEmoji("");
                            props.setActiveCategory(category.key);
                          }}
                          className={clsx(
                            "p-2 text-zinc-500 hover:bg-zinc-100 hover:text-zinc-600",
                            category.isActive ? "border-b" : ""
                          )}
                        >
                          <category.icon className={clsx("h-5 w-5")} />
                        </button>
                      </li>
                    ))}
                  </ul>
                  {(props.activeCategory === "search" ||
                    !props.activeCategory) && (
                    <div
                      className="px-2 py-2.5"
                      ref={props.categoryRefs["search"]}
                    >
                      <input
                        type="text"
                        ref={props.searchRef}
                        placeholder={t("translation:common:search_all_emoji")}
                        className={clsx(
                          "block h-8 w-full rounded-md border border-zinc-300 shadow-sm",
                          "focus:border-0 focus:ring-2 focus:ring-zinc-400 sm:text-sm"
                        )}
                        onChange={(e) => props.onSearchEmoji(e.target.value)}
                      />
                    </div>
                  )}
                  {props.filteredEmojis && (
                    <div className="h-96 overflow-y-auto px-2">
                      {props.filteredEmojis.length === 0 && (
                        <p className="px-2 text-xs">
                          {t("translation:common:no_results")}
                        </p>
                      )}
                      <ul className="relative grid grid-cols-8">
                        {props.filteredEmojis.map((emoji) => (
                          <li key={`result-emoji-${emoji.key}`}>
                            <button
                              type="button"
                              onClick={() => props.toggleReaction(emoji.key)}
                              className={clsx(
                                "h-8 w-8 rounded p-1 text-xl hover:bg-zinc-100",
                                props.isLoading && "cursor-wait"
                              )}
                              disabled={props.isLoading}
                            >
                              {emoji.value}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!props.filteredEmojis && (
                    <ul className="h-96 overflow-y-auto px-2">
                      {props.categories
                        .filter((category) =>
                          props.activeCategory !== "search"
                            ? category.key === props.activeCategory
                            : category.key !== "search"
                        )
                        .map((category) => (
                          <li
                            key={`category-${category.key}`}
                            ref={
                              category.key !== "search"
                                ? props.categoryRefs[category.key]
                                : undefined
                            }
                          >
                            <h3 className="my-1 px-1 text-sm font-semibold capitalize">
                              {t(`translation:reaction:${category.key}`)}
                            </h3>
                            <ul className="relative grid grid-cols-8">
                              {category.emojis.map((emoji) => (
                                <li key={`emoji-${emoji.key}`}>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      props.toggleReaction(emoji.key)
                                    }
                                    className={clsx(
                                      "h-8 w-8 rounded p-1 text-xl hover:bg-zinc-100",
                                      props.isLoading && "cursor-wait"
                                    )}
                                    disabled={props.isLoading}
                                    data-tooltip-id="confetti"
                                    data-tooltip-content={emoji.key}
                                  >
                                    {emoji.value}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

EmoticonModal.displayName = "EmoticonModal";

export { EmoticonModal };
