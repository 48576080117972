/**
 *
 *
 * <Reactions />
 *
 *
 */
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";

import { EmoticonModal } from "../../containers/EmoticonModal";
import { useReactions } from "./useReactions";

export const Reactions = (props: ReturnType<typeof useReactions>) => {
  return (
    <>
      <ul className="flex items-center gap-2">
        {Object.keys(props.existingReactions).map((key) => (
          <li key={props.existingReactions[key].key}>
            <button
              type="button"
              onClick={() =>
                props.toggleReaction(props.existingReactions[key].key)
              }
              className={clsx(
                "text-zinc-300 ring-1 ring-zinc-300 hover:text-zinc-400",
                "rounded-full px-1 text-xl font-medium leading-6 hover:bg-zinc-50",
                "flex items-center",
                props.isLoading && "cursor-wait"
              )}
              data-tooltip-id="tooltip"
              data-tooltip-html={props
                .truncateNameList(props.existingReactions[key].names)
                .join("<br/>")}
              data-tooltip-place="top"
              disabled={props.isLoading}
            >
              <span>{props.existingReactions[key].value}</span>
              <p className="ml-0.5 text-xs">
                {props.existingReactions[key].count}
              </p>
            </button>
          </li>
        ))}

        <li>
          <button
            ref={props.emojiButtonRef}
            className={clsx(
              "rounded-md text-zinc-500 hover:bg-zinc-100",
              "flex items-center gap-1 hover:text-zinc-600"
            )}
            onClick={() => props.onEmoticonModal(true)}
          >
            <FaceSmileIcon className="h-6 w-6" />
          </button>
        </li>
      </ul>
      <EmoticonModal
        isOpen={props.isEmoticonModal}
        onClose={() => props.onEmoticonModal(false)}
        isLoading={props.isLoading}
        toggleReaction={props.toggleReaction}
        emojiBtnRect={props.emojiBtnRect}
      />
    </>
  );
};
