/**
 *
 *
 * useReactions
 *
 *
 */
import {
  BugAntIcon,
  CakeIcon,
  FaceSmileIcon,
  FlagIcon,
  GlobeAmericasIcon,
  HeartIcon,
  MagnifyingGlassIcon,
  MusicalNoteIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { HeroIcon } from "types";

import { useDebounce } from "../../hooks/useDebounce";
import {
  Emoji,
  EmojiCategory,
  EmojiKey,
  emojiCategories,
  emojiList,
} from "../../utils/emojis";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  emojiBtnRect?: DOMRect;
  isLoading?: boolean;
  toggleReaction: (emojiKey: EmojiKey) => void;
}

const HEIGHT = 474;
const BUTTON_WIDTH = 24;
const BUTTON_HEIGHT = 28;

export const useEmoticonModal = (props: Props) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [top, setTop] = useState<number>();
  const [right, setRight] = useState<number>();
  const categoryIcons: Record<EmojiCategory, HeroIcon> = {
    search: MagnifyingGlassIcon,
    people: FaceSmileIcon,
    nature: BugAntIcon,
    food: CakeIcon,
    travel: GlobeAmericasIcon,
    activities: TrophyIcon,
    objects: HeartIcon,
    symbols: MusicalNoteIcon,
    flags: FlagIcon,
  };

  const categoryRefs = {
    search: useRef<HTMLDivElement>(null),
    people: useRef<HTMLLIElement>(null),
    nature: useRef<HTMLLIElement>(null),
    food: useRef<HTMLLIElement>(null),
    travel: useRef<HTMLLIElement>(null),
    activities: useRef<HTMLLIElement>(null),
    objects: useRef<HTMLLIElement>(null),
    symbols: useRef<HTMLLIElement>(null),
    flags: useRef<HTMLLIElement>(null),
  };

  const [activeCategory, setActiveCategory] = useState<EmojiCategory>("search");
  const categories: {
    key: EmojiCategory;
    emojis: Emoji[];
    icon: HeroIcon;
    isActive: boolean;
  }[] = (Object.keys(emojiCategories) as EmojiCategory[]).map((key) => ({
    key,
    emojis: emojiCategories[key],
    icon: categoryIcons[key],
    isActive: activeCategory === key,
  }));

  /**
   * onSearchEmoji
   */
  const [filteredEmojis, setFilteredEmojis] = useState<Array<Emoji> | null>(
    null
  );
  const onSearchEmoji = useDebounce((value: string) => {
    if (value.trim() === "") {
      setFilteredEmojis(null);
      return;
    }
    setFilteredEmojis(
      emojiList.filter((emoji: Emoji) => emoji.key.indexOf(value) > -1)
    );
  }, 500);

  useEffect(() => {
    if (props.isOpen) {
      setFilteredEmojis(null);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.emojiBtnRect) {
      const { x, y } = props.emojiBtnRect;
      const { innerWidth, innerHeight } = window;
      let top = y + BUTTON_HEIGHT;
      if (top + HEIGHT > innerHeight) {
        top = y - HEIGHT;
      }
      setRight(innerWidth - x - BUTTON_WIDTH);
      setTop(top);
    }
  }, [props.emojiBtnRect]);

  return {
    categories,
    activeCategory,
    setActiveCategory,
    categoryRefs,
    searchRef,
    filteredEmojis,
    setFilteredEmojis,
    onSearchEmoji,
    right,
    top,
    ...props,
  };
};
