/**
 *
 *
 * useChallengeWizardInvitePeoples
 *
 *
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { SearchParam } from "../../consts";
import useDebounceValue from "../../hooks/useDebounceValue";
import { useToast } from "../../hooks/useToast";
import {
  useChallengesGroupsCreate,
  useChallengesGroupsMembersList,
  useChallengesGroupsPartialUpdate,
  useChallengesRetrieve,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import { usePeopleList } from "../../services/teambuilder/endpoints/people/people";
import {
  GroupRequest,
  PatchedGroupRequest,
  PeopleListOrdering,
  SimpleUserModel,
} from "../../services/teambuilder/schemas";

const schema = z
  .object({
    startAt: z.coerce
      .date()
      .min(new Date(), "translation:validation:start_date_be_future"),
    endAt: z.coerce
      .date()
      .min(new Date(), "translation:validation:end_date_be_future"),
  })
  .refine((data) => data.startAt < data.endAt, {
    message: "translation:validation:end_date_after_start_date",
    path: ["endAt"],
  });

export type FormData = z.infer<typeof schema>;
const ROW_LIMIT = 30;

export const useChallengeWizardInvitePeoples = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const challengeId = Number(searchParams.get(SearchParam.CHALLENGE));
  const { openToast } = useToast();

  const [members, setMemebers] = useState<SimpleUserModel[]>([]);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);

  /**
   * Challenge requests
   */
  const { data: challenge } = useChallengesRetrieve(
    challengeId,
    {},
    {
      query: {
        enabled: challengeId > 0,
        select: (data) => data,
      },
    }
  );
  /**
   *
   * Left Column
   * Load all peoples
   *
   */
  const [searchPeople, setSearchPeople] = useState<string>();
  const [peoplesLimit, setPeoplesLimit] = useState(ROW_LIMIT);
  const debouncedSearchTerm = useDebounceValue(searchPeople, 500) || "";

  const peopleListRequestParams = {
    limit: peoplesLimit,
    offset: 0,
    ordering: PeopleListOrdering.first_name,
    search: debouncedSearchTerm,
  };

  const { data: peoplesResponse, isFetching: isLoadingMembers } = usePeopleList(
    peopleListRequestParams,
    {
      query: {
        keepPreviousData: true,
      },
    }
  );

  const { data: allPeoplesResponse } = usePeopleList({
    limit: 1,
    offset: 0,
    ordering: PeopleListOrdering.first_name,
  });

  const [peoples, setPeoples] = useState<SimpleUserModel[]>([]);
  const [morePeoples, setMorePeoples] = useState<boolean>();

  const onAddMemeber = (people: SimpleUserModel) => {
    setMemebers([...members, people]);
  };

  const [addAll, setAddAll] = useState(false);
  const onAddAll = () => {
    setPeoplesLimit(peoplesResponse?.meta.pagination.total || 999);
    setAddAll(true);
  };

  const loadMorePeoples = () => {
    setPeoplesLimit((prev) => prev + ROW_LIMIT);
  };

  /**
   *
   * Right column
   * Load group members
   *
   */
  const [searchMember, setSearchMember] = useState<string>();

  const onRemoveMember = (people: SimpleUserModel) => {
    setMemebers(members.filter((member) => member.id !== people.id));
  };
  const onRemoveAll = () => {
    setMemebers([]);
  };

  const [groupId, setGroupId] = useState<number>();
  const { data: invitees } = useChallengesGroupsMembersList(
    challengeId,
    groupId!,
    {
      limit: 999,
    },
    {
      query: {
        enabled: challengeId > 0 && groupId !== undefined && groupId > 0,
      },
    }
  );

  useEffect(() => {
    if (challenge && challenge.groups && challenge.groups?.length > 0) {
      setGroupId(challenge.groups[0].id);
    }
  }, [challenge]);

  useEffect(() => {
    if (invitees && invitees.data.length > 0) {
      setMemebers(invitees.data);
      if (peoplesLimit < invitees.data.length) {
        setPeoplesLimit(invitees.data.length);
      }
    }
  }, [invitees, peoplesLimit]);

  useEffect(() => {
    const peoples =
      (peoplesResponse?.data.filter(
        (people) => !members.find((member) => member.id === people.id)
      ) as SimpleUserModel[]) || [];
    setPeoples(peoples);
    setMorePeoples(peoplesResponse?.meta.pagination.hasMore);
    if (
      peoples.length < ROW_LIMIT &&
      peoplesResponse?.meta.pagination.hasMore &&
      peoplesLimit === peoplesResponse?.data.length
    ) {
      loadMorePeoples();
    }
  }, [peoplesResponse, members]);

  useEffect(() => {
    if (
      addAll &&
      !peoplesResponse?.meta.pagination.hasMore &&
      peoplesResponse?.data &&
      peoplesResponse?.data.length > 0
    ) {
      setMemebers(peoplesResponse?.data as SimpleUserModel[]);
      setAddAll(false);
    }
  }, [peoplesResponse, addAll]);

  /**
   * Create challenge group
   */
  const { mutate: createChallengesGroups, isLoading: isCreating } =
    useChallengesGroupsCreate({
      mutation: {
        onSuccess: () => {
          // Forward use to the challenge detail page
          openToast({
            title: t("translation:toast:challenges_groups_create_success"),
          });

          let url = `/challenges/wizard/add-activities?${searchParams.toString()}`;
          if (isNextButtonClicked) {
            url = `/challenges/wizard/select-dates?${SearchParam.CHALLENGE}=${challengeId}`;
          }
          navigate(url);
        },
        onError: () => {
          openToast({
            title: t("translation:toast:challenges_groups_create_failed"),
            type: "danger",
          });
        },
      },
    });

  /**
   * Create challenge group
   */
  const { mutate: updateChallengesGroups, isLoading: isUpdating } =
    useChallengesGroupsPartialUpdate({
      mutation: {
        onSuccess: () => {
          // Forward use to the challenge detail page
          openToast({
            title: t("translation:toast:challenges_groups_update_success"),
          });

          let url = `/challenges/wizard/add-activities?${searchParams.toString()}`;
          if (isNextButtonClicked) {
            url = `/challenges/wizard/select-dates?${SearchParam.CHALLENGE}=${challengeId}`;
          }
          navigate(url);
        },
        onError: () => {
          openToast({
            title: t("translation:toast:challenges_groups_update_failed"),
            type: "danger",
          });
        },
      },
    });

  /**
   * onCancel : click on Cancel Button
   */
  const onCancel = () => {
    navigate("/challenges");
  };

  /** Update or create challenge */
  const onUpdateOrCreate = () => {
    if (!challengeId) return;
    if (!groupId) {
      const data: {
        challengesPk: number;
        data: GroupRequest;
      } = {
        challengesPk: challengeId!,
        data: {
          members: members.map((member) => member.id!),
        },
      };
      createChallengesGroups(data);
    } else {
      const data: {
        challengesPk: number;
        id: number;
        data: PatchedGroupRequest;
      } = {
        challengesPk: challengeId,
        id: groupId,
        data: {
          members: members.map((member) => member.id!),
        },
      };
      updateChallengesGroups(data);
    }
  };

  /**
   * go back
   */
  const onBack = () => {
    setIsNextButtonClicked(false);
    onUpdateOrCreate();
  };

  /**
   * next
   */
  const onNext = async () => {
    setIsNextButtonClicked(true);
    onUpdateOrCreate();
  };

  return {
    onCancel,
    onBack,
    onNext,

    allPeoples: allPeoplesResponse?.meta.pagination.total || 0,
    peoples,
    members,
    onAddMemeber,
    onAddAll,
    onRemoveAll,
    onRemoveMember,

    searchPeople,
    setSearchPeople,
    searchMember,
    setSearchMember,

    morePeoples,
    loadMorePeoples,

    isCreating,
    isUpdating,
    isLoadingMembers,
    addAll,
  };
};
