/**
 *
 *
 * useChallengeWizardDates
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PatchedChallengeRequest } from "services/teambuilder/schemas";
import { z } from "zod";

import { SearchParam } from "../../consts";
import { useToast } from "../../hooks/useToast";
import {
  useChallengesPartialUpdate,
  useChallengesRetrieve,
} from "../../services/teambuilder/endpoints/challenges/challenges";

const schema = z
  .object({
    startAt: z.coerce
      .date()
      .min(new Date(), "translation:validation:start_date_be_future"),
    endAt: z.coerce
      .date()
      .min(new Date(), "translation:validation:end_date_be_future"),
  })
  .refine((data) => data.startAt < data.endAt, {
    message: "translation:validation:end_date_after_start_date",
    path: ["endAt"],
  });

export type FormData = z.infer<typeof schema>;

export const useChallengeWizardDates = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const challengeId = Number(searchParams.get(SearchParam.CHALLENGE));
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);

  /**
   * Challenge requests
   */
  const { data: challenge, isInitialLoading: isLoadingChallenge } =
    useChallengesRetrieve(
      challengeId,
      {},
      {
        query: {
          enabled: challengeId > 0,
          select: (data) => data,
        },
      }
    );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    ...form
  } = useForm<FormData>({
    defaultValues: {},
    resolver: zodResolver(schema),
  });
  const startAt = useWatch({ control: form.control, name: "startAt" });
  const endAt = useWatch({ control: form.control, name: "endAt" });

  const onSubmit = useCallback(() => {
    const data: { id: number; data: PatchedChallengeRequest } = {
      id: challengeId,
      data: {
        startAt: new Date(startAt).toISOString(),
        endAt: new Date(endAt).toISOString(),
      },
    };
    updateChallenge(data);
  }, [startAt, endAt]);

  useEffect(() => {
    if (challenge && challenge.startAt && challenge.endAt) {
      setValue("startAt", dayjs(challenge.startAt).toDate());
      setValue("endAt", dayjs(challenge.endAt).toDate());
    }
  }, [challenge]);

  /**
   * update challenge
   */
  const { mutate: updateChallenge, isLoading: isLoadingUpdateChallenge } =
    useChallengesPartialUpdate({
      mutation: {
        onSuccess: (_data, variables) => {
          // Forward use to the challenge detail page
          openToast({ title: t("translation:toast:challenge_update_success") });
          let url = `/challenges/wizard/invite-people?${searchParams.toString()}`;
          if (isNextButtonClicked) {
            url = `/challenges/wizard/publish?${SearchParam.CHALLENGE}=${variables.id}`;
          }
          navigate(url);
        },
        onError: () => {
          openToast({
            title: t("translation:toast:challenge_update_failed"),
            type: "danger",
          });
        },
      },
    });

  /**
   * onCancel : click on Cancel Button
   */
  const onCancel = () => {
    navigate("/challenges");
  };

  /**
   * go back
   */
  const onBack = handleSubmit(() => {
    setIsNextButtonClicked(false);
    onSubmit();
  });

  /**
   * next
   */
  const onNext = handleSubmit(() => {
    setIsNextButtonClicked(true);
    onSubmit();
  });

  return {
    isLoading: isLoadingChallenge || isLoadingUpdateChallenge,

    onCancel,
    onBack,
    onNext,

    startAt,
    endAt,
    register,
    errors,
  };
};
