/**
 *
 *
 * <ProofContent />
 *
 *
 */
import {
  ArrowTopRightOnSquareIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { Trans } from "react-i18next";
import { NavItemV3 } from "types";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { SimpleUserModel } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";

type Props = {
  className?: string;
  selectedRecipient?: SimpleUserModel;
  selectedValueId?: number;
  selectedValueLabel?: string;
  previewImageUrl?: string;
  selectedURL?: string;
  menuItems: NavItemV3[];
};

export const FeatureContent = (props: Props) => {
  if (
    !props.selectedRecipient &&
    !props.selectedValueId &&
    !props.selectedValueLabel &&
    !props.previewImageUrl &&
    !props.selectedURL
  )
    return null;

  return (
    <div
      className={clsx(
        "relative flex items-start gap-x-3 rounded-lg bg-white shadow-lg",
        !props.previewImageUrl && "px-3 py-2.5",
        props.className
      )}
    >
      <div className="flex-1 space-y-3">
        {props.selectedRecipient && (
          <div className="flex flex-row gap-x-4">
            <div
              className={clsx(
                "h-9 w-9 rounded-full sm:h-10 sm:w-10",
                "flex flex-shrink-0 items-center justify-center",
                "text-white",
                "bg-fuchsia-950 hover:shadow disabled:opacity-60"
              )}
            >
              <MegaphoneIcon className="h-6 w-6" />
            </div>
            <div className="flex-1 text-sm font-normal text-zinc-900">
              <Trans
                i18nKey={
                  props.selectedValueId
                    ? "translation:post:shoutout_to_value"
                    : "translation:post:shoutout_to"
                }
                values={{
                  name: fullName(props.selectedRecipient),
                  value: props.selectedValueLabel,
                }}
                components={{
                  a: (
                    <span className="font-medium text-fuchsia-950 underline" />
                  ),
                }}
              />
            </div>
          </div>
        )}
        {props.selectedURL && (
          <div className="flex flex-row gap-x-4">
            <a
              href={props.selectedURL}
              className="flex flex-1 flex-row items-center gap-x-1 break-all text-sm font-medium"
              target="blank"
            >
              {props.selectedURL}
              <ArrowTopRightOnSquareIcon className="h-5 w-5 flex-shrink-0" />
            </a>
          </div>
        )}
        {props.previewImageUrl && (
          <div className="flex flex-row gap-x-4">
            <div className="relative overflow-hidden rounded-lg">
              <img src={props.previewImageUrl} alt="" aria-hidden="true" />
            </div>
          </div>
        )}
      </div>
      <div
        className={clsx(
          "flex justify-end",
          props.previewImageUrl && "absolute right-2 top-2"
        )}
      >
        <DropdownMenu
          items={props.menuItems}
          menuButtonContent={(open) => (
            <EllipsisHorizontalIcon
              className={clsx(
                "h-5 w-5 cursor-pointer rounded-md bg-white hover:bg-zinc-100 hover:text-zinc-600",
                open ? "bg-zinc-200 text-zinc-600" : "text-zinc-500"
              )}
            />
          )}
          menuClassNames={clsx("right-0")}
        />
      </div>
    </div>
  );
};
