/**
 *
 *
 * usePostModal
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useMe } from "../../hooks/useMe";
import { usePostModalContent } from "../../hooks/usePostModalContent";
import { SimpleUserModel } from "../../services/teambuilder/schemas";
import { convertHeic } from "../../utils/heic-to-jpg";
import { PostFeatureType } from "./PostAttachmentModal";

export const schema = z.object({
  url: z.union([
    z
      .string()
      .regex(
        // accepts full url or url without protocol
        // https://www.linkedin.com or www.linkedin.com
        /^(?:(?:https?|http):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i,
        { message: "translation:validation:invalid_url" }
      )
      .nullish(),
    z.literal(""),
  ]),
});

export type FormData = z.infer<typeof schema>;

type Props = {
  mode?: PostFeatureType;
  isOpen: boolean;
  isChallengeComplete?: boolean;
  onClose: () => void;
  onCancel: () => void;

  recipient?: SimpleUserModel;
  valueId?: number;

  previewImageUrl?: string;

  url?: string;

  onSubmit: (data: {
    previewImage?: Blob;
    previewImageUrl?: string;
    recipient?: SimpleUserModel;
    valueId?: number;
    url?: string;
  }) => void;
};

export const usePostAttachmentModal = (props: Props) => {
  /**
   * Get requests
   */
  const { me } = useMe();
  const { values } = usePostModalContent();

  const [selectedRecipient, setSelectedRecipient] = useState<SimpleUserModel>();
  const [selectedValueId, setSelectedValueId] = useState<number>();

  const [previewImage, setPreviewImage] = useState<Blob>();
  const [previewImageUrl, setPreviewImageUrl] = useState<string>();

  /**
   *
   * File input
   *
   */
  const [isHeicToJpg, setIsHeicToJpg] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFocusBack = () => {
    window.removeEventListener("focus", handleFocusBack);
    setTimeout(() => {
      if (
        inputFileRef.current?.files?.length === 0 &&
        props.mode === PostFeatureType.IMAGE
      ) {
        props.onCancel();
      }
    }, 300);
  };

  const onClickInput = () => {
    inputFileRef.current?.click();
    window.addEventListener("focus", handleFocusBack);
  };

  const onFileAdded = async (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0] as File;
    if (file.name.match(/.*\.heic$/i)) {
      setIsHeicToJpg(true);
      file = await convertHeic(URL.createObjectURL(file));
      setIsHeicToJpg(false);
    }
    setPreviewImage(file);
    setPreviewImageUrl(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (props.isOpen && props.mode === PostFeatureType.IMAGE) {
      if (inputFileRef.current) inputFileRef.current.value = "";
      // Wait for mounting of Post Modal
      const timer = setTimeout(() => {
        onClickInput();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [props.isOpen, props.mode]);

  /**
   * Form
   */
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      url: undefined,
    },
  });

  const onSubmit = (formData: FormData) => {
    if (props.mode === PostFeatureType.SHOUTOUT) {
      props.onSubmit({
        recipient: selectedRecipient,
        valueId: selectedValueId,
      });
      return;
    }
    if (props.mode === PostFeatureType.IMAGE) {
      props.onSubmit({
        previewImage,
        previewImageUrl,
      });
      return;
    }
    if (props.mode === PostFeatureType.LINK && formData.url) {
      const url =
        formData.url?.indexOf("http") === -1
          ? `https://${formData.url}`
          : formData.url;
      props.onSubmit({
        url,
      });
      return;
    }
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  useEffect(() => {
    setPreviewImageUrl(props.previewImageUrl);
  }, [props.previewImageUrl]);

  useEffect(() => {
    setSelectedRecipient(props.recipient);
    setSelectedValueId(props.valueId);
  }, [props.recipient, props.valueId]);

  useEffect(() => {
    form.setValue("url", props.url);
  }, [props.url]);

  const isNextDisabled =
    (props.mode === PostFeatureType.SHOUTOUT && !selectedRecipient) ||
    (props.mode === PostFeatureType.IMAGE && !previewImageUrl) ||
    (props.mode === PostFeatureType.LINK && !!form.formState.errors.url);

  return {
    me,
    form,
    onHandleSubmit,
    values,

    isHeicToJpg,
    onFileAdded,
    inputFileRef,

    selectedRecipient,
    setSelectedRecipient,
    selectedValueId,
    setSelectedValueId,

    isNextDisabled,

    previewImageUrl,
    mode: props.mode,
    onCancel: props.onCancel,
    onClose: props.onClose,
    isOpen: props.isOpen,
    isChallengeComplete: props.isChallengeComplete,
  };
};
